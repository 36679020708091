<template>
  <div class="wrap">
    <!-- <van-nav-bar title="查看医嘱" left-arrow @click-left="onClickLeft" /> -->
    <!-- <div style="padding: 16px 0 0 25px;font-weight: bold;font-size: 16px; ">{{name}}（{{ cardNo }}）</div> -->
    <!-- <div class="head_box">
      <div class="box_info">
        <div class="box_left">
          <img src="../../assets/images/head.png" style="width:40px;height:40px" />
          <div class="box_left1">
            <div class="left1_1">{{ name }}</div>
            <div class="left1_2">{{ cardNo }}</div>
          </div>
        </div>
        <img src="../../assets/images/head_right.png" style="width:46px;height:46px" />
      </div>
    </div> -->
    <div class="content">
      <div class="content_info" v-for="(item, i) in dataList" :key="i" @click="goDetail(item)">
        <div class="title">
          <div class="title_1">{{ item.examItems }}</div>
          <div class="title_2">{{ orderStatus[item.resultStatus] }}</div>
        </div>
        <div class="detail">患者：{{item.name}}</div>
        <div class="detail">报告时间：{{ item.reportDateTime }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { getList } from "@/api/electronicQuery";

export default {
  data() {
    return {
      active: 1,
      value: "",
      name: "测试账号名称",
      cardNo: this.$route.query.cardNo,
      dataList: [],
      orderStatus: {
        1: "未出报告",
        2: "未出报告",
        3: "未出报告",
        4: "报告已出",
        9: "其他",
      },
    };
  },
  created() {
    window.scrollTo(0, 0)
    this.getdata();
  },
  methods: {
    getdata() {
      getList({
        patientId: this.$route.query.cardNo,
      }).then((res) => {
        if (res.data.code == "200") {
          this.dataList = res.data.data;
          this.name = res.data.data.name;
          this.cardNo = res.data.data.cardNo;
        }
      });
    },
    onClickLeft() {
      this.$router.push({ path: "/electronicQuery" });
    },
    goDetail(item) {
      // return
      if (item.resultStatus == "4") {
        localStorage.setItem("itemData",JSON.stringify(item))
        this.$router.push({
          path: "/electronicQueryDetail",
          query: { cardNo: this.$route.query.cardNo},
        });
      } else {
        Toast("未出报告，请耐心等待")
      }
    },
  },
};
</script>
<style lang="less" scoped>
.wrap {
  .head_box {
    background-color: #f4f8fb;
    padding: 16px 16px 0 16px;
    box-sizing: border-box;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    .box_info {
      border: 1px solid #8ebdfb;
      background: #ffffff;
      border-radius: 10px 10px 10px 10px;
      padding: 12px 10px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .box_left {
        display: flex;

        .box_left1 {
          margin-left: 12px;
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .left1_1 {
            color: #333333;
            font-size: 15px;
            font-weight: bold;
          }

          .left1_2 {
            color: #999999;
            font-size: 15px;
          }
        }
      }
    }
  }

  .content {
    padding: 16px;
    box-sizing: border-box;

    .content_info {
      background: #ffffff;
      border-radius: 10px 10px 10px 10px;
      padding: 12px;
      box-sizing: border-box;
      margin-bottom: 12px;

      .title {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #dcdcdc;
        padding-bottom: 13px;

        .title_1 {
          font-size: 15px;
          color: #333333;
          font-weight: bold;
        }

        .title_2 {
          font-size: 15px;
          color: #333;
        }
      }

      .detail {
        color: #666666;
        font-size: 14px;
        margin-top: 12px;
      }
    }
  }
}

/deep/.van-cell-group {
  margin: 16px;
  border-radius: 10px;
}

/deep/.van-cell {
  border-radius: 10px;
}
</style>
